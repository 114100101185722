import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import api from '../api/api';
import DriversDeleteComponent from '../components/Drivers/DriverListDelete/Index';

const get_drivers = async (params = {}) => {
    const response = await api.get('/driver/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {
            pagination: {},
            result: []
        }
    }
}


const DriversDelete = () => {
    let location = useLocation()
    const [pagination, setPagination] = useState({});
    const [drivers, setDrivers] = useState([]);


    useEffect(() => {
        async function get_drivers_fetch() {
            let response = await get_drivers({ is_active: "False" })
            setPagination(response.pagination)
            setDrivers(response.result)
            console.log(response);
        }
        get_drivers_fetch();
    }, []);

    const search_driver = async (params) => {
        let response = await get_drivers(params);
        setPagination(response.pagination)
        setDrivers(response.result)
    }


    return (
        <DriversDeleteComponent
            pagination={pagination}
            drivers={drivers}
            setDrivers={setDrivers}
            setPagination={setPagination}
            search_driver={search_driver}
            nav_page={location?.state?.page}
        />
    )
}


export default DriversDelete;