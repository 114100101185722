export const DASHBOARD = "/";
export const DRIVERS = "/drivers";
export const CARS = "/cars";
export const BALANCE = "/balance";
export const STAFFS = "/staff";
export const SETTINGS = "/settings";
export const NOTIFICATION = "/notification";
export const DRIVER_ADD = "/driver-add";
export const CAR_ADD = "/car-add";
export const STAFF_ADD = "/staff-add";
export const DRIVER_DETAIL = "/driver-detail";
export const VEDMOST = "/vedmost";
export const PRICE_LIST = "/price-list";
export const CAR_DETAIL = "/car-detail";