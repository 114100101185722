import React, { useEffect, useState } from 'react';
import { Container, DriverContainer, TitleContainer, Title, AddButton, Search, BtnContainer } from './styles';
import * as ROUTES from "../../../Constants/routes";
import { DetailBtn, StatusSpan } from '../../Drivers/DriverList/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../Table/styles';
import { FaEdit, FaEye, FaTrash, FaMoneyBill, FaAngleDown, FaAngleUp, FaFileExcel } from 'react-icons/fa';
import { get_permission } from '../../../Constants/get_permission';
import PaginationComponent from '../../Paginations';
import api from '../../../api/api';
import Swal from 'sweetalert2';
import CarDeleteModal from '../DeleteModal';
import { useNavigate } from 'react-router-dom';
import { Select } from '../CarEdit/styles';
import { ExportBtn } from '../../Balance/Payments/styles';


const get_staffs = async (params = {}) => {
    const response = await api.get('/account/', { params: params })
    if (response.data.success) {
        return response.data.data
    } else {
        return []
    }
}


const CarsComponent = ({ search_car, pagination, setPagination, cars }) => {
    let navigate = useNavigate();
    const [permission, setPermission] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [search_text, setSearch_text] = useState("")
    const [delete_id, setDelete_id] = useState(null)
    const [isopenD, setIsOpenD] = useState(false);
    const [filter_c, setFilter_c] = useState(null)
    const [inspektor, setInspektor] = useState(0);
    const [staffs, setStaffs] = useState([])
    const delete_car = async (car_id, comment) => {
        setIsOpenD(false)
        const response = await api.put(`/car-edit/${car_id}/`, { comment: comment, method: "delete" })
        if (response.data.success === true) {
            setIsOpenD(false)
            Swal.fire({
                title: 'Success!',
                text: "Mashina o'chirildi!",
                icon: 'success'
            });

            search_car({
                search: search_text,
                page: currentPage,
                is_active: true
            })
        } else {

            Swal.fire({
                title: 'Error!',
                text: "Mashina o'chirishda xatolik mavjud!",
                icon: 'error'
            });
        }

    }
    useEffect(() => {
        async function get_staffs_fetch() {
            let res = await get_staffs({ select: true })
            console.log(res);
            setStaffs(res)
        }
        get_staffs_fetch()
    }, [])
    const search = (e) => {
        setSearch_text(e.target.value)
        const params = {
            search: e.target.value,
            is_active: true
        }
        search_car(params);
    }
    const loadData = page => {
        setCurrentPage(page)
        if (filter_c !== null) {
            if (inspektor === 0) {
                search_car({ page: page, is_active: true, credit: filter_c })

            } else {
                search_car({ page: page, is_active: true, credit: filter_c, inspektor: inspektor })

            }

        } else {
            if (inspektor === 0) {
                search_car({ page: page, is_active: true })

            } else {
                search_car({ page: page, is_active: true, inspektor: inspektor })

            }

        }
    }
    const open_delete = (id) => {
        setDelete_id(id);
        setIsOpenD(true)
    }
    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])

    useEffect(() => {
        setCurrentPage(pagination.current_page)
    }, [pagination])

    const click_credit = () => {
        if (filter_c === true) {
            setFilter_c(false)
            search_car({
                is_active: true,
                credit: false,
                inspektor: inspektor
            })
        } else if (filter_c === false) {
            setFilter_c(null)
            search_car({
                is_active: true,
                inspektor: inspektor

            })
        } else if (filter_c === null) {
            setFilter_c(true)
            search_car({
                is_active: true,
                credit: true,
                inspektor: inspektor

            })
        }
    }
    const export_data = async () => {

        const response = await api.get('/export-driver/', { params: { inspektor: inspektor } })
        console.log(response.data)
        if (response.data.success === true) {
            window.open("https://apicrm.webtaxi.uz" + response.data.data)
        }
    }
    const InspektorChange = (value) => {
        setInspektor(value)
        search_car({ is_active: true, inspektor: value })
    }
    return (
        <Container>
            <TitleContainer>
                <Title>Mashinalar</Title>
                <Select onChange={(e) => InspektorChange(e.target.value)} style={{ marginLeft: 'auto', marginRight: "150px", height: '32px' }}>
                    {inspektor === 0 ?
                        <option selected value={0}>Barcha Inspektorni</option> :
                        <option value={0}>Barcha Inspektorni</option>
                    }

                    {staffs?.map((md, index) => (
                        <option value={md.id} selected={md.id === inspektor ? "selected" : null}>{md.name}</option>
                    ))}

                </Select>
                <ExportBtn onClick={() => export_data()}><FaFileExcel /></ExportBtn>
                <Search placeholder='Qidirish ... ' onChange={(e) => search(e)} />
                {permission?.includes(18) ?
                    <AddButton onClick={() => navigate('/cars-delete', { state: { page: currentPage } })}>O'chirilganlar</AddButton> : null}
                {permission?.includes(3) ?
                    <AddButton onClick={() => navigate(ROUTES.CAR_ADD, { state: { page: currentPage } })}>Qo'shish</AddButton> : null}
            </TitleContainer>
            <DriverContainer>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TR>
                                <TH>№</TH>
                                <TH>Mashina rusumi</TH>
                                <TH>Davlat raqami</TH>
                                <TH>Haydovchi</TH>
                                <TH>Inspektor</TH>
                                <TH style={{ cursor: "pointer" }} onClick={() => click_credit()}>Qarz
                                    {filter_c === true ? <FaAngleUp style={{ marginLeft: "15px" }} size={14} /> : filter_c === false ? <FaAngleDown style={{ marginLeft: "15px" }} size={14} /> : null}</TH>
                                <TH>Litsenziya</TH>
                                <TH></TH>
                            </TR>
                        </TableHead>
                        <TableBody>
                            {cars?.map((dat, index) => (
                                <TR key={index}>
                                    <TD>{index + (pagination?.current_page - 1) * pagination.number_of_object + 1}</TD>
                                    <TD>{dat.model}</TD>
                                    <TD>{dat.number}</TD>
                                    <TD>{dat.driver}</TD>
                                    <TD>{dat.inspektor}</TD>
                                    <TD>{dat.credit}</TD>
                                    <TD>{dat.let_date_end}</TD>
                                    <TD>
                                        <BtnContainer key={index}>
                                            <DetailBtn bg="#32a852" onClick={() => navigate(`/car-payment/${dat.id}`, { state: { page: currentPage } })}><FaMoneyBill /></DetailBtn>
                                            <DetailBtn bg="#4287f5" onClick={() => navigate(`/car-detail/${dat.id}`, { state: { page: currentPage } })}><FaEye /></DetailBtn>
                                            {permission.includes(4) ? <DetailBtn bg="#f5c842" onClick={() => navigate(`/car-edit/${dat.id}`, { state: { page: currentPage } })}><FaEdit /></DetailBtn> : null}
                                            {permission.includes(17) ? <DetailBtn bg="red" to={"#"} onClick={() => open_delete(dat.id)}><FaTrash /></DetailBtn> : null}
                                        </BtnContainer>
                                    </TD>

                                </TR>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </DriverContainer>
            <CarDeleteModal isOpen={isopenD} setIsOpen={setIsOpenD} DeleteCar={delete_car} car_id={delete_id} />
            {pagination?.pages_count > 1 ?
                <PaginationComponent
                    activePage={pagination.current_page}
                    items_count={pagination.items_count}
                    range_display={5}
                    loadData={loadData}
                    items_per_page={pagination.number_of_object}
                /> : null}
        </Container>
    );
}

export default CarsComponent;