import styled from "styled-components";

export const Table = styled.table`
    width: 100%;
`;

export const TableContainer = styled.div`
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const TH = styled.th`
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #22242C;
    padding: 7px;
`;

export const TR = styled.tr`
`;

export const TD = styled.td`
    text-align: left;
    padding: 7px;
    font-family: "Inter";
    font-style: normal;
    font-size: 14px;
    line-height: 15px;
    color: #22242C;
    height: 34px;
`;



