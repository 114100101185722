import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import api from '../api/api';
import StaffAddComponent from '../components/Staff/StaffAdd';

const get_permission = async () => {
    const response = await api.get('/permission/')
    if (response.data.success === true) {
        return response.data.data
    } else {
        return []
    }
}
const StaffAdd = () => {
    const [permissions, setPermissions] = useState([])
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function get_permissions_fetch() {
            const r = await get_permission()
            setPermissions(r)
        }
        get_permissions_fetch()

    }, [])
    const add_staff = async (data, clear) => {
        if (loading === false) {
            const response = await api.post("/account/", data)
            setLoading(true)
            if (response.data.success === true) {
                Swal.fire({
                    title: 'Success!',
                    text: "Xodim qo'shildi!",
                    icon: 'success'
                });
                setLoading(false)
                clear();
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: "Xodim qo'shishda xatolik mavjud!",
                    icon: 'error'
                });
                setLoading(false)
            }
        }
    }
    return (
        <StaffAddComponent permissions={permissions} add_staff={add_staff} />
    );
}

export default StaffAdd;