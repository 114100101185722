import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    border: 1px solid #999;
    gap: 20px;
    padding: 15px;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.span`
    font-size: ${props=>props.size};
    font-family: "Inter";
    font-weight: 600;
    font-style: normal;
    margin-bottom: 7px;
`;

export const Text = styled.span`
    
`;

export const SubText = styled.div`
    font-size: ${props=>props.size};
    font-family: "Inter";
    font-style: normal;
`;

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    padding: 10px;
`;

export const ItemContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
`;

export const IjaraContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
`;
