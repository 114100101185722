import styled from "styled-components";


export const Container = styled.div`
    display:flex;
    flex-direction:column;
    background-color:white;
    border-radius:10px;
    padding:10px;
    width: 300px;
`;

export const Form = styled.form`
    display:flex;
    flex-direction:column;
    margin-top: 10px;
    margin-bottom:10px;
`;

export const BtnDiv = styled.div`
    display:flex;
    gap:10px;
`;

export const Btn = styled.button`
    background-color:${props=>props.bg?props.bg:"#2bb36c"};
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 7px;
    color: white;
    border:none;
    cursor: pointer;
    :hover{
        opacity: 0.6;
    }
`;