import React from 'react'
import { Container, FirstDiv, H, P, SecondDiv, Text } from './styles';

const get_current_date = (date) => {
    if (date.getDate() < 10) {
        var day = "0" + String(date.getDate())
    } else {
        var day = String(date.getDate())
    }
    if (date.getMonth() < 9) {
        var month = "0" + String(date.getMonth() + 1)
    } else {
        var month = String(date.getMonth() + 1)
    }

    return day + "." + month + "." + String(date.getFullYear())
}
const ContractComponent = React.forwardRef((props, ref) => {
    let date = new Date()
    return (
        <Container ref={ref}>
            <FirstDiv>
                <div style={{ display: "block" }}>
                    <Text style={{ float: "left" }}>{get_current_date(date)} yil</Text>
                    <Text style={{ float: "right" }}>Andijon shaxar</Text>
                </div>
                <H style={{ marginTop: "10px", marginBottom: "10px" }}>SHARTNOMA</H>
                <P style={{ textAlign: "center" }}>(Avtomashinani boshqarish uchun) </P>
                <H>1.Shartnoma mazmuni</H>
                <P>1.1 Bir tarafda “Dream Gold Andijon” mas’uliyati cheklangan jamiyati nizomi asosida harakat qiluvchi jamiyat ta’sischisi  Andijon viloyati Andijon shahar Toshkent ko’chasi 97-uyda ro’yxatda turuvchi Usmonov Muhammadjon Mashrabjon o’gli kelgusida “Tashkilot” deb yuritiladi.</P>
                <P>
                    Ikkinchi tarafda <b>{props?.contract?.car?.owner_name}dan</b> shartnoma asosida olingan <b>{props?.contract?.car?.model}</b> rusumli <b>{props?.contract?.car?.year}</b>-yilda ishlab chiqarilgan davlat raqami <b>{props?.contract?.car?.number}</b> bo'lgan avtomashinaga egasining tavsiyasiga asosan  <b>{props?.contract?.driver?.address}</b>da propiskada turuvchi,  <b>{props?.contract?.driver?.birthdate}</b>-yilda tug'ilgan  (<b>{props?.contract?.driver?.passport}</b>   <b>{props?.contract?.driver?.kim_tomonidan}</b> ) (<b>JShShIR: {props?.contract?.driver?.jshshir}</b>)  <b>{props?.contract?.driver?.name}</b>ga ushbu avtomashina yo’l varaqalari orqali topshiriladi va kelgusida  “Xaydovchi” deb yuritiladi. </P>
                <P>1.2 Taraflar kelishuviga asosan «<b>{props?.contract?.price_list?.name}</b>» tarifiga o’tkazildi va tarif (a’zolik badali) to’lovi kuni  har oyning <b>{props?.contract?.day}</b>-kuni etib belgilandi </P>
                <P>1.3 Shartnoma bo’yicha “Tashkilot” “Xaydovchi”ning boshqarmasi xisoblanadi. </P>
                <P>1.4 Ishning boshlanish va tugash vaqti “Xaydovchi”ni o’z ihtiyori asosida tashkil etiladi. </P>
                <H>2. Xaydovchining majburiyatlari</H>
                <Text>2.1 Mehnat va texnik intizomi (ichki mehnat tartib) qoidalari va yo’riqnomalariga amal qilishi lozim. </Text>
                <Text>2.2 Ish boshlashidan oldin “Tashkilot” a’zolik badalini to’liqligicha to’lab bo’lgan bo’lishi va dispecherlik punktlarida kunlik tibbiy va texnik ko’riklardan o’tishi hamda ruhsat etilgan faoliyatini amalga oshirishi shart.</Text>
                <Text>2.3 Yo’nalishlarda boshqa shahsga avtomashina bermaslik.</Text>
                <Text>2.4 Texnika xavfsizligi, harakat xavfsizligi qoidalariga amal qilishi. </Text>
                <Text>2.5 Shartnoma imzolangan kundan avtomashina litsenziya bilan ta’minlanmaguniga qadar pullik xizmat ko’rsatmaslik va yo’l kira narxlarini o’z boshimchalik bilan o’zgartirmaslik.</Text>
                <Text>2.6 Xaydovchilik guvohnomasini va boshqa hujjatlarni o’z yonida olib yurish. </Text>
                <Text>2.7 Agarda “Xaydovchi”ning sog’ligida muammo bo’lgan  taqdirda zudlik bilan shifokorga murojaat qilishi va “Tashkilot” ni ogohlantirishi avtomashinani boshqa egasiga topshirishi shart.</Text>
                <P>2.7.1 “Xaydovchi” shartnomani 2.7 bandida nazarda tutilgan holatda majburiyatni bajarmagan taqdirda  shartnomani 1.2 bandida nazarda tutilgan azolik badalini sababsiz kechiktirgan hisoblanadi va  2.8 bandida belgilab ketilgan jarimalari bilan tulash majburiyatini oladi.</P>
                <Text>2.8 Shartnomani 1.2 bandida nazarda tutilgan a’zolik badali to’lovlarini sababsiz kechiktirgan taqdirda har bir kechiktirilgan kun uchun jarima sifatida tariff umumiy summasidan 1%  jarima to’laydi.</Text>
                <Text>2.9 Avtomashina texnik jihatdan ishga yaroqsiz bo’lganida avtomashinani ta’mirlashdan oldin “Tashkilot” ga yozma ogohlantirishi shart.</Text>
                <P>2.9.1 Avtomashina 2.9 nazarda tutilgan tartibga asosan texnik ta’mirdam chiqgandan so’ng ta’mirni bajargan servisdan yo’riqnomada nazarda tutilgan talablarga javob beradigan ta’mir  ma’lumotnomasi taqdim qiladi. </P>
                <P>2.9.2 Yuqorida 2.9.1 bandida nazarda tutilgan majburiyat bajarilmagan taqdirda shartnomani 1.2 bandida nazarda tutilgan a'zolik badali va ushbu shartnomani 2.8 badida belgilab o’tilgan jarimalar to’lash majburiyatini oladi</P>
                <Text>2.10 “Xaydovchi” avtomashina egasi tavsiyasiga ko’ra ishga qa’bul qilinganligi inobadga olgan holda Ijara shartnomasida belgilab o’tilgan ijara haqini avtomashina egasiga har oyda to'lab borish majburiyatini oladi.</Text>
                <P>2.10.1 “Xaydovchi” Ijara shartnomada belgilangan ijara haqidan daromad soliqni har oylik to’lab borishni va vaqtida to’lamagan taqdirda soliq qonunchiligida belgilangan penya va jarimalar bilan birgalikda to’lashni zimmasiga oladi.</P>
                <Text>2.11 Avtomobilni texnik xolatini doimiy nazoratga olish va o’z manzilida yoki avtomobil egasini manzilida saqlash.</Text>
                <Text>2.12 Avtomobil kunlik ehtiyoji uchun yoqilg’ini o’z hisobidam quyish.</Text>
                <Text>2.13 “Xaydovchi” shartnomani 3.10 va 3.11 bandida nazarda tutilgan hujjatlar muddati tugashidan 10 kun oldin ariza berib yangi hujjat rasmiylashtirish kerav bo’ladi aks holda barcha oqibatlar uchun o’zi javobgar hisoblanadi.</Text>
                <Text>2.14 “Xaydovchi” O’zbekiston Respublikasi Mehnat kodeksi tegishli moddalariga asosan oylik ish haqlarini “Tashkilot” tomonidan qonuniylashtirib berilgan faoliyati orqali o’ziga oylik ish haqi ishlab topadi ba qonunchilikga asosan oylik ish haqi “Mehnatga haq to’lashni eng kam miqdori”da soliq qonunchiligiga asosan daromad soligini to'lash majburiyatini o’z zimasiga oladi.</Text>
            </FirstDiv>
            <SecondDiv>
                <H>3.Tashkilot majburiyati</H>
                <Text>3.1 Yo’nalishlarda ish bilan ta’minlash, “Xaydovchi” mehnatini muhofaza qilish va havfsizlik texnikasi qoidalari, lavozim yo’riqnomalari, jamoa shartnomasi va boshqa va maxalliy hujjatlar bilan tanishtirish. </Text>
                <Text>3.2 “Xaydovchi” ni kunlik ko’rikdan va avtomollarni texnik ko’rikdan o’tkazish.</Text>
                <Text>3.3 Avtomobillarni ta’mirlash xamda 1 va 2 – texnik xizmatlarni qo’shimcha to’lovlar evaziga bajarish.</Text>
                <Text>3.4 Mehnat va ishlab chiqarish intizomini ta’minlash. </Text>
                <Text>3.5 Ushbu shartnoma imzolangan kundan 10 kun ichida litsenziya varaqasi bilan ta’minlash.</Text>
                <Text>3.6 Xavfsizlik va samarali mexnat shart sharoitlarini yaratish, “Xaydovchi” ni o’qitish, unga mehnat xavszlik shart sharoitlari yaratish to’g’risida yo’l-yo’riq berish.  </Text>
                <Text>3.8 Ish joyini mehnatni muhofaza qilish va havszilik texnikasi qoidalariga muvofiq jihozlash. </Text>
                <Text>3.9 “Xaydovchi” na’munali ishlash intizomiga belgilangan rejalarni to’liq bajarishiga qarab keying muddatlarga litsenziya varaqalari bilan ta’minlash.  </Text>
                <Text>3.10 “Tashkilot” ushbu shartnoma imzolangandan so’ng 10 kun muddat ichida avtomobillar uchun majburiy sug’urta qilib beradi.</Text>
                <Text>3.11 Tashkilot” ushbu shartnoma imzolangandan so’ng shartnoma muddati tugagunga qadar barcha kunlik texnik ko’rik tashkil etish bo’yicha barcha moliyaviy harajatlarni o’z hisobidan ro’laydi.  </Text>
                <H>4. Moliyaviy bandi</H>
                <Text>4.1 Shartnomani 1.2 bandida nazarda tutilgan a’zolik to’lovlaridan “Tashkilot”  “Xaydovchi” uchun oylik ish haqi va mukofot pullari berish majburiyatini olmaydi.</Text>
                <Text>4.2 “Xaydovchi” shartnomani 2.14 bandida belgilab o’tilgan tartibiga asosan o’ziga ish haqi ishlab topadi va soliq to’laydi. </Text>
                <Text>4.3 “Xaydovchi” to’lovlarni sababsiz kechiktirgan taqdirda yashash manziliga va avtomobil egasi manziliga ogohlantirish yuboriladi. </Text>
                <H>5. Shartnoma boshqa shartlari </H>
                <Text>5.1 Taraflardan biri shartnoma majburiyatlaruni Fors-mojor holatini yuzaga kelishi natijasida bajarmaganlarida ikkinchi tomon uni javobgar deb topa olmaydi.</Text>
                <Text>5.2 “Xaydovchi” uzi tomonidan buzilgan yo’l harakati qoidalariga, meyoriy hujjatlar kamchiligi, sodir etgan YTX va boshqa avtomobil va fuqarolarga yetkazilgan zararga shaxsan o’zi to’liq javobgar bo’ladi.</Text>
                <Text>5.3 “Xaydovchi”ga “Tashkilot” tomonidan berilgan yo’l varaqasida muhrsiz harakatlangan taqdirda avtomashina egasi ruhsatisiz boshqargan hisoblanadi va oqibatlariga o’zi javobgar bo’ladi.  </Text>
                <Text>5.4 Tomonlar bir-birining majburiyatlariga javobgar emas. </Text>
                <Text>5.5 “Xaydovchi” to’lovlarni sababsiz kechiktirgan taqdirda yashash manziliga va avtomobil egasi manziliga ogohlantirish hati yuboriladi va hech qanday to’lovlar amalga oshirilmagan taqdirda sudga murojaat qilinadi shartnomani 1.2 bandida belgilangan tarif bo’yicha to’lovlar 2 (ikki) borabar undiriladi. </Text>
                <Text>5.6 Shartnoma shartlari bo’yicha “Xaydovchi” majburiyatlarini bajarmagan taqdirda “Tashkilot” qonunchilikda belgilangan tartibda sudga murojaat qiladi hamda barcha sud harajatlari “Xaydovchi” yuklatiladi. </Text>
                <H>6. Yakuniy shartlar </H>
                <Text>6.1 Ushbu shartnomaga o’zgarishlar, ko’shimchalar yozma ravishda tuzilib har ikki tomon imzolagandan so’ng kuchga kiradi.  </Text>
                <Text>6.2 Ushbu shartnoma muddatsiz va “Tashkilot” majburiyatlar bajarilmagan taqdirda bir taraflama bekor qiladi yoki “Xaydovchi” shartnomadagi barcha majburiyatlarni bajarib bo’lganidan so’ng ariza qibil ikki taraflama bekor qilinishi mumkin.</Text>
                <Text>6.3 Shartnoma muddati <b>{props?.contract?.date_start}</b>-yildan <b>{props?.contract?.date_end}</b>-yilgacha etib belgilandi</Text>
                <H>IMZOLAR:</H>
                <Text style={{ fontWeight: "bold" }}>Yuqorida ko’rsatilgan barcha shartlarni o’rganib chiqdim va roziligimni imzoim bilan tasdiqlayman:</Text>
                <Text style={{ marginTop: "25px" }}>“Tashkilot”:______________________________________________________________________________________________________________________________</Text>
                <Text style={{ marginTop: "25px" }}>
                    “Xodim”:__________________________________________________________________________________________________________________________________
                </Text>
                <Text style={{ marginTop: "25px" }}>Ro’yhatga olindi:</Text>
            </SecondDiv>
        </Container>
    );
})

export default ContractComponent;