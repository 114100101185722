import React, { useState, useEffect } from 'react'
import api from '../api/api';
import StaffComponent from '../components/Staff/StaffList/Index';

const get_staffs = async (params = {}) => {
    const response = await api.get('/account/', { params: params })
    if (response.data.success) {
        return response.data.data
    } else {
        return []
    }
}

const Staffs = () => {
    const [staffs, setStaffs] = useState([])

    useEffect(() => {
        async function get_staffs_fetch() {
            const res = await get_staffs()
            setStaffs(res)
        }
        get_staffs_fetch()
    }, [])


    const search_staff = async (params) => {
        const res = await get_staffs(params)
        setStaffs(res)
    }

    const delete_account = async (id) => {
        const res = await api.delete(`/account/${id}/`)
        if (res.data.success) {
            setStaffs(res.data.data)
        }
    }
    return (
        <StaffComponent staffs={staffs} delete_account={delete_account} search_staff={search_staff} />
    )
}


export default Staffs;