import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../api/api';
import StaffEditComponent from '../components/Staff/StaffEdit';

const get_permission = async () => {

    const response = await api.get('/permission/')
    if (response.data.success === true) {
        return response.data.data
    } else {
        return []
    }
}

const get_staff = async (id) => {

    const response = await api.get(`/account/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    } else {
        return []
    }
}
const StaffEdit = () => {
    const { staff_id } = useParams()
    const [permissions, setPermissions] = useState([])
    const [staff, setStaff] = useState({})
    useEffect(() => {
        async function get_permissions_fetch() {
            const r = await get_permission()
            setPermissions(r)
        }
        get_permissions_fetch()

    }, [])
    useEffect(() => {
        async function get_staff_fetch() {
            const r = await get_staff(staff_id)
            setStaff(r)
        }
        get_staff_fetch();
    }, [staff_id])
    const add_staff = async (data) => {
        console.log(data)
        const response = await api.put(`/account/${staff_id}/`, data)
        console.log(response.data);
        if (response.data.success === true) {
            Swal.fire({
                title: 'Success!',
                text: "Xodim tahrirlandi!",
                icon: 'success'
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Xodim tahrirlashda xatolik mavjud!",
                icon: 'error'
            });
        }
    }
    return (
        <StaffEditComponent permissions={permissions} add_staff={add_staff} stff={staff} />
    );
}

export default StaffEdit;