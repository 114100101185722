import React, { useRef } from 'react'
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { Container } from '../CarTab/styles';
import PathMapComponent from './path_map';
import { ButtonContainer, PrintButton } from './styles';

const Documents = ({ driver, car }) => {
    const pathRef = useRef();
    const handlePathPrint = useReactToPrint({
        content: () => pathRef.current,
    });
    return (
        <Container>
            <ButtonContainer>
                <PrintButton bg="#0d9445" onClick={handlePathPrint}>
                    <FaPrint size={24} style={{ paddingRight: "10px" }} />
                    Yo'l varaqasi
                </PrintButton>
            </ButtonContainer>
            <PathMapComponent ref={pathRef} car={car} driver={driver} />
        </Container>
    );
}

export default Documents;