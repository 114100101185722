import React, { useEffect, useState } from 'react';
import api from '../api/api';
import Swal from 'sweetalert2'
import DriverEditComponent from '../components/Drivers/DriverEdit';
import { useLocation, useParams } from 'react-router-dom';

const get_driver = async (id) => {
    const response = await api.get(`/driver-edit/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return null
    }
}

const DriverEdit = () => {
    const { driver_id } = useParams()

    const [driver, setDriver] = useState({});
    let location = useLocation()
    const add_driver = async (data) => {
        const response = await api.post(`/driver-edit/${driver_id}/`, data)
        if (response.data.success === true) {
            setDriver(response.data.data)
            Swal.fire({
                title: 'Success!',
                text: "Haydovchi Tahrirlandi!",
                icon: 'success'
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Haydovchi tahrirlashda xatolik mavjud!",
                icon: 'error'
            });
        }
    }
    useEffect(() => {
        async function get_driver_fetch() {
            let response = await get_driver(driver_id)
            setDriver(response)
        }
        get_driver_fetch();
    }, [driver_id])
    return (
        <DriverEditComponent add_driver={add_driver} driver={driver} nav_page={location?.state?.page} />
    );
}

export default DriverEdit;