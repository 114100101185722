import React, { useState } from 'react';
import { Container, Title, TitleContainer } from '../../Cars/CarList/styles';
import { Button, Form, FormItem, Input, Select, Label } from './styles';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BackBtn } from '../../Drivers/DriverEdit/styles';

const CarAddComponent = ({ add_car, models, staffs, nav_page }) => {
    let navigate = useNavigate();
    const [owner_name, setOwnerName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [passport, setPassport] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [jshshir, setJshshir] = useState("");
    const [stir, setStir] = useState("");
    const [model, setModel] = useState(0);
    const [number, setNumber] = useState("");
    const [raqam, setRaqam] = useState("");
    const [year, setYear] = useState("");
    const [tex_passport, setTex_passport] = useState("");
    const [tex_date, setTex_date] = useState("");
    const [date_start, setDate_start] = useState("");
    const [date_end, setDate_end] = useState("");
    const [price, setPrice] = useState("");
    const [inspektor, setInspektor] = useState(0);

    const clear = () => {
        setOwnerName("");
        setAddress("");
        setPhone("");
        setPassport("");
        setBirthdate("");
        setJshshir("");
        setStir("");
        setModel(0);
        setInspektor(0)
        setNumber("");
        setRaqam("");
        setYear("");
        setTex_passport("");
        setTex_date("");
        setDate_start("");
        setDate_end("");
        setPrice("");
    }

    const add = (e) => {
        const data = {
            owner_name: owner_name, address: address, phone: phone, passport: passport, birthdate: birthdate, jshshir: jshshir,
            stir: stir, model: model, number: number, raqam: raqam, year: year,
            tex_passport: tex_passport, tex_date: tex_date, date_start: date_start, date_end: date_end, price: price, inspektor: inspektor
        }
        add_car(data, clear)
        e.preventDefault();
    }
    const return_back = () => {
        navigate('/cars', { state: { page: nav_page } })
    }
    return (
        <Container>
            <BackBtn onClick={return_back}><FaLongArrowAltLeft /></BackBtn>
            <TitleContainer>
                <Title>Mashina qo'shish</Title>
            </TitleContainer>
            <Form onSubmit={(e) => add(e)}>
                <FormItem>
                    <Label>Mashina Rusumi</Label>
                    <Select onChange={(e) => setModel(e.target.value)}>
                        {model === 0 ?
                            <option selected disabled>Mashina rusumini tanlang</option> :
                            <option disabled>Mashina rusumini tanlang</option>
                        }

                        {models?.map((md, index) => (
                            <option value={md.id}>{md.name}</option>
                        ))}

                    </Select>
                </FormItem>
                <FormItem>
                    <Label>Mashina davalat raqami</Label>
                    <Input type="text" placeholder='Mashina  davalat raqami' onChange={(e) => setNumber(e.target.value)} value={number} required />
                </FormItem>
                <FormItem>
                    <Label>Ishlab CH.Y</Label>
                    <Input type="number" placeholder='Ishlab CH.Y' onChange={(e) => setYear(e.target.value)} value={year} required />
                </FormItem>
                <FormItem>
                    <Label>Texpasporti</Label>
                    <Input type="text" placeholder='Texpasporti seria raqami' onChange={(e) => setTex_passport(e.target.value)} value={tex_passport} required />
                </FormItem>
                <FormItem>
                    <Label>Texpasprot berilgan sana</Label>
                    <Input type="date" placeholder='Tex pasprot berilgan sana' onChange={(e) => setTex_date(e.target.value)} value={tex_date} required />
                </FormItem>
                <FormItem>
                    <Label>Egasi F.I.O</Label>
                    <Input type="text" placeholder='F.I.O' onChange={(e) => setOwnerName(e.target.value)} value={owner_name} required />
                </FormItem>
                <FormItem>
                    <Label>Manzil</Label>
                    <Input type="text" placeholder='Manzil' onChange={(e) => setAddress(e.target.value)} value={address} required />
                </FormItem>
                <FormItem>
                    <Label>Tug'ilgan yil</Label>
                    <Input type="date" placeholder='Tugilgan yil' onChange={(e) => setBirthdate(e.target.value)} value={birthdate} required />
                </FormItem>
                <FormItem>
                    <Label>Telefon raqami</Label>
                    <Input type="text" placeholder='Telefon raqami' onChange={(e) => setPhone(e.target.value)} value={phone} required />
                </FormItem>
                <FormItem>
                    <Label>Pasport seriya raqami</Label>
                    <Input type="text" placeholder='Pasport seriya raqami' onChange={(e) => setPassport(e.target.value)} value={passport} required />
                </FormItem>
                <FormItem>
                    <Label>JSHSHIR</Label>
                    <Input type="text" placeholder='JSHSHIR' onChange={(e) => setJshshir(e.target.value)} value={jshshir} required />
                </FormItem>
                <FormItem>
                    <Label>STIR</Label>
                    <Input type="number" placeholder='STIR' onChange={(e) => setStir(e.target.value)} value={stir} required />
                </FormItem>
                <FormItem>
                    <Label>Ijara raqami</Label>
                    <Input type="text" placeholder='Ijara raqami' onChange={(e) => setRaqam(e.target.value)} value={raqam} required />
                </FormItem>
                <FormItem>
                    <Label>Ijara boshlanish sana</Label>
                    <Input type="date" onChange={(e) => setDate_start(e.target.value)} value={date_start} required />
                </FormItem>
                <FormItem>
                    <Label>Ijara tugash sana</Label>
                    <Input type="date" onChange={(e) => setDate_end(e.target.value)} value={date_end} required />
                </FormItem>
                <FormItem>
                    <Label>Ijara narxi</Label>
                    <Input type="number" placeholder='Ijara narxi' onChange={(e) => setPrice(e.target.value)} value={price} required />
                </FormItem>
                <FormItem>
                    <Label>Inspektor</Label>
                    <Select onChange={(e) => setInspektor(e.target.value)}>
                        {inspektor === 0 ?
                            <option selected disabled>Inspektorni tanlang</option> :
                            <option disabled>Inspektorni tanlang</option>
                        }

                        {staffs?.map((md, index) => (
                            <option value={md.id} selected={md.id === inspektor ? "selected" : null}>{md.name}</option>
                        ))}

                    </Select>
                </FormItem>
                <FormItem>
                    <Label></Label>
                    <Button>Qo'shish</Button>
                </FormItem>

            </Form>
        </Container>
    );
}

export default CarAddComponent;