import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import api from '../api/api';
import DriversComponent from '../components/Drivers/DriverList/Index';

const get_drivers = async (params = {}) => {
    const response = await api.get('/driver/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {
            pagination: {},
            result: []
        }
    }
}


const Drivers = () => {
    const { state } = useLocation()
    const [pagination, setPagination] = useState({});
    const [drivers, setDrivers] = useState([]);
    useEffect(() => {
        async function get_drivers_fetch() {
            let params = { is_active: true }
            if (state?.page === undefined) {
                params = {
                    is_active: true,
                }
            } else {
                params = {
                    is_active: true,
                    page: state.page
                }
            }
            let response = await get_drivers(params)
            setPagination(response.pagination)
            setDrivers(response.result)
        }
        get_drivers_fetch();
    }, []);

    const search_driver = async (params) => {
        let response = await get_drivers(params);
        setPagination(response.pagination)
        setDrivers(response.result)
    }


    return (
        <DriversComponent
            pagination={pagination}
            drivers={drivers}
            setDrivers={setDrivers}
            setPagination={setPagination}
            search_driver={search_driver}
        />
    )
}


export default Drivers;