import styled from "styled-components";

export const SecondDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FirstDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px;
    grid-gap: 20px;
    position: relative;
    @media print{
        @page {
            size: a4;
            margin: 30px;
        }
        padding: 0px;
        max-height: 596mm;
        ${SecondDiv}{
            min-height: 297mm;
            max-height: 297mm;
            position: relative;
            margin-top: 270px;
        }
        ${FirstDiv}{
            min-height: 297mm;
            max-height: 297mm;
            position: relative;
        }
    }

`;

export const H = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: Cambria;
`;

export const P = styled.span`
    font-size: 20px;
    text-indent: 50px;
    text-align: justify;
    font-family: Cambria;
`;

export const Text = styled.span`
    font-size: 20px;
    line-height: 30px;
    font-family: Cambria;
`;