import api from "../api/api";

export const get_permission = async () =>{
    const account = localStorage.getItem('account')
    const req = await api.get(`/account/${account}/`)
    if (req.data.success === true){
        return req.data.data.permission
    }else{
        return []
    }
}




