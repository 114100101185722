import React, { useEffect, useState } from "react";
import { Title } from "../Dashboard/Cards/styles";
import { Button, Input } from "../Drivers/Tabs/PaymentTab/styles";
import { Container, Form } from "./styles";
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../Table/styles';
import { get_permission } from "../../Constants/get_permission";
import { DetailBtn } from "../Drivers/DriverList/styles";
import { FaCheck, FaPencilAlt } from "react-icons/fa";

const PriceListComponent = ({priceLists,add_price_list, update_price_list}) =>{
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [price_letter, setPrice_letter] = useState("");
    const [extra_price, setExtraPrice] = useState("");
    const [extra_price_letter, setExtraPriceLetter] = useState("");
    const [permission, setPermission] = useState([])
    const [editItem, setEditItem] = useState(null)
    const AddPriceList = (e) =>{
        if (editItem != null){
            const data = {
                pk:editItem?.id,
                name:name,
                price:price,
                price_letter:price_letter,
                extra_price:extra_price,
                extra_price_letter:extra_price_letter
            }
            update_price_list(data);

        }else{
            const data = {
                name:name,
                price:price,
                price_letter:price_letter,
                extra_price:extra_price,
                extra_price_letter:extra_price_letter
            }
            add_price_list(data);
        }
        e.preventDefault();
        setName("")
        setPrice("")
        setExtraPrice("")
        setPrice_letter("")
        setExtraPriceLetter("")
        setEditItem(null)
    }

    const editBtnClick = (data) => {
        setName(data?.name)
        setPrice(data?.price)
        setPrice_letter(data?.price_letter)
        setExtraPrice(data?.extra_price)
        setExtraPriceLetter(data?.extra_price_letter)
        setEditItem(data)
    }

    useEffect(()=>{
        async function get_permission_fetch(){
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])
    return (
        <Container>
            <Title style={{fontSize:"20px"}}>Tariflar</Title>
            {permission?.includes(10)?
            <Form onSubmit={AddPriceList}>
                <Input type="text" placeholder="Tarif nomi" 
                    onChange={(e)=>setName(e.target.value)}
                    required
                    value={name}
                    style={{marginRight:"10px"}}/>
                <Input type="number" placeholder="Tarif narxi" 
                onChange={(e)=>setPrice(e.target.value)}
                required
                value={price}
                style={{marginRight:"10px"}}/>
                <Input type="text" placeholder="Tarif narxi harflar bilan" 
                onChange={(e)=>setPrice_letter(e.target.value)}
                required
                value={price_letter}
                style={{marginRight:"10px"}}/>
                <Input type="number" placeholder="Qo'chimcha summa" 
                onChange={(e)=>setExtraPrice(e.target.value)}
                required
                value={extra_price}
                style={{marginRight:"10px"}}/>
                <Input type="text" placeholder="Qo'chimcha summa harflar bilan" 
                onChange={(e)=>setExtraPriceLetter(e.target.value)}
                required
                value={extra_price_letter}
                style={{marginRight:"10px"}}/>
                <Button>{editItem != null ?"Tahrirlash":"Qo'shish"}</Button>
            </Form>:null}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>#</TH>
                            <TH>Tarif nomi</TH>
                            <TH>Tarif narxi</TH>
                            <TH>Tarif narxi yozuvda</TH>
                            <TH>Qo'shimcha summa</TH>
                            <TH>Q.S yozuvda</TH>
                            <TH>Active</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {priceLists.map((dat, index)=>(
                            <TR>
                                <TD>{index+1}</TD>
                                <TD>{dat.name}</TD>
                                <TD>{dat.price}</TD>
                                <TD>{dat.price_letter}</TD>
                                <TD>{dat.extra_price}</TD>
                                <TD>{dat.extra_price_letter}</TD>
                                <TD>{dat.is_active?"active":"deactive"}</TD>
                                <TD><DetailBtn style={{width:'30px', height:'30px'}} bg={"#e28743"} to={`#`} onClick={() => {editBtnClick(dat)}}> <FaPencilAlt size={18} /></DetailBtn></TD>
                            </TR>
                        ))}
                        
                    </TableBody>
                </Table>
        </TableContainer>
        </Container>
    );
}

export default PriceListComponent;