import styled from "styled-components";

export const DateInput = styled.input`
    height: 32px;
    width: 150px;
    border: none;
    border-bottom:1px solid #999;
    margin-right: 15px;
    margin-left: 15px;
    outline: none;
    font-family: "Inter";
    font-size: 15px;
    
`;

export const ExportBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    background-color: #0384fc;
    border-radius: 5px;
    border: none;
    height: 36px;
    width: 36px;
    cursor: pointer;
    margin-right: 15px;
    transition: all 0.5s;
    &:hover{
        transform: scale(1.03);
    }
`;