import styled from 'styled-components';


export const Form = styled.form`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 15px;
  margin-top: 10px;
`;

export const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
`;

export const Label = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    padding-left: 5px;
    color: #8E8EA1;
`;

export const Input = styled.input`
    border: 1px solid #8E8EA1;
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 5px;
    font-family: "Inter";
    height: 36px;
    outline: none;
    :focus{
        border: 1px solid #03cafc;
    }
    border-radius: 8px;
`;

export const Select = styled.select`
    border: 1px solid #8E8EA1;
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 5px;
    font-family: "Inter";
    height: 36px;
    outline: none;
    :focus{
        border: 1px solid #03cafc;
    }
    border-radius: 8px;
`;

export const Button = styled.button`
    background-color: #6160DC;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    height: 36px;
    width: 200px;
    color: white;
    outline: none;
    border: none;
    border-radius: 8px;
    :hover{
        opacity: 50%;
    }
    margin-top:10px;
    margin-bottom: 10px;
    
`;