import React, { useEffect, useState } from 'react';
import Paginations from '../../Paginations';
import { Container, DriverContainer, TitleContainer, Title, AddButton, Search } from './styles';
import * as ROUTES from "../../../Constants/routes";
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../Table/styles';
import { DetailBtn, StatusSpan } from '../../Drivers/DriverList/styles';
import { FaTrash, FaEdit, FaCheck, FaLock } from "react-icons/fa";
import { get_permission } from '../../../Constants/get_permission';
import PaginationComponent from '../../Paginations';
import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import Swal from 'sweetalert2';
import PaswordEditModal from '../EditModel';
const StaffComponent = ({ staffs, delete_account, search_staff }) => {
    let navigate = useNavigate();
    const [permission, setPermission] = useState([])
    const [isopenE, setIsOpenE] = useState(false);
    const [edit_id, setEdit_id] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filter_search, setFilterSearch] = useState(null)
    const { currentPage, setCurrentPage } = useState(1)
    const search = (value) => {
        setFilterSearch(value)
        search_staff({ search: value })
    }
    const loadData = page => {
        setCurrentPage(page)
        search_staff({ page: page })
    }
    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])
    const EditParol = async (data) => {
        if (loading === false) {
            const response = await api.put(`/account/${edit_id}/`, data)
            setLoading(true)
            if (response.data.success === true) {
                search_staff({
                    search: filter_search,
                    page: currentPage
                })
                setIsOpenE(false)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
    }

    const Click_Parol = (id) => {
        setEdit_id(id)
        setIsOpenE(true)
    }

    return (
        <Container>
            <TitleContainer>
                <Title>Xodimlar</Title>
                <Search placeholder='Qidirish ... ' onChange={(e) => search(e.target.value)} />
                {permission?.includes(11) ? <AddButton to={ROUTES.STAFF_ADD}>Qo'shish</AddButton> : null}
            </TitleContainer>
            <DriverContainer>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TR>
                                <TH>№</TH>
                                <TH>FIO</TH>
                                <TH>Telefon</TH>
                                <TH>Status</TH>
                                {permission?.includes(13) ? <TH>O'chirish</TH> : null}
                                {permission?.includes(12) ? <TH>Tahriralsh</TH> : null}
                                {permission?.includes(22) ? <TH>Parol</TH> : null}
                            </TR>
                        </TableHead>
                        <TableBody>
                            {staffs?.result?.map((dat, index) => (
                                <TR>
                                    <TD>{index + 1}</TD>
                                    <TD>{dat.name}</TD>
                                    <TD>{dat.phone}</TD>
                                    <TD>{dat.is_active ? <StatusSpan bg="green">Active</StatusSpan> : <StatusSpan bg="red">O'chirilgan</StatusSpan>}</TD>
                                    {permission?.includes(13) ? <TD><DetailBtn bg={dat.is_active === true ? "#fc0307" : "#039903"} to={`#`} onClick={() => delete_account(dat.id)}>
                                        {dat.is_active === true ? <FaTrash /> : <FaCheck />}</DetailBtn></TD> : null}
                                    {permission?.includes(12) ? <TD><DetailBtn bg="#f5c842" onClick={() => navigate(`/staff-edit/${dat.id}`)}><FaEdit /></DetailBtn></TD> : null}
                                    {permission?.includes(22) ? <TD><DetailBtn bg="#f5c842" onClick={() => { Click_Parol(dat.id) }}><FaLock /></DetailBtn></TD> : null}
                                </TR>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </DriverContainer>
            <PaswordEditModal isOpen={isopenE} setIsOpen={setIsOpenE} EditParol={EditParol} />
            <PaginationComponent
                activePage={staffs?.pagination?.current_page}
                items_count={staffs?.pagination?.items_count}
                range_display={6}
                loadData={loadData}
                items_per_page={staffs?.pagination?.number_of_object}
            />
        </Container>
    );
}

export default StaffComponent;