import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import api from '../api/api';
import LitsenziyaComponent from '../components/Litsenziya/LitsenziyaComponent';

const get_cars = async (params = {}) => {
    const response = await api.get('/car/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const get_cars_lets = async (params = {}) => {
    const response = await api.get('/car-let/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {
            pagination: {},
            result: []
        }
    }
}


const Litsenziya = () => {
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(false);
    const [carLets, setCarLets] = useState([]);
    const [pagination, setPagination] = useState({});

    const add_let = async (data, clear_let, car_id) => {
        if (loading === false) {
            const response = await api.post(`/add-let/${car_id}/`, data)
            setLoading(true)
            if (response.data.success === true) {
                setLoading(false)
                Swal.fire({
                    title: 'Success!',
                    text: "Litsenziya yangilandi!",
                    icon: 'success'
                });
                clear_let()
            } else {
                setLoading(false)
                Swal.fire({
                    title: 'Error!',
                    text: "Litsenziya yangilashda xatolik mavjud!",
                    icon: 'error'
                });
            }
        }

    }

    useEffect(() => {
        async function get_cars_fetch() {
            let response = await get_cars({
                select: true,
                is_active: true
            })
            setCars(response)
        }
        get_cars_fetch();
    }, []);
    useEffect(() => {
        async function get_cars_let_fetch() {
            let response = await get_cars_lets({})
            console.log(response);
            setCarLets(response.result)
            setPagination(response.pagination)
        }
        get_cars_let_fetch();
    }, []);

    const loadCarsLets = async (params) => {
        let response = await get_cars_lets(params)
        setCarLets(response.result)
        setPagination(response.pagination)
    }
    return (
        <LitsenziyaComponent cars={cars} add_let={add_let} carLets={carLets} pagination={pagination} loadCarsLets={loadCarsLets} />
    )
}

export default Litsenziya;