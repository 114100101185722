import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api/api';
import ShartnomaComponent from '../components/Drivers/Shartnoma';

const get_contracts = async (id) => {
    const response = await api.get(`/contract/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return null
    }
}

const get_cars = async (params = {}) => {
    const response = await api.get('/car/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const get_price_list = async () => {
    const response = await api.get(`/price-list/`)

    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const Shartnoma = () => {
    const { driver_id } = useParams()
    const [contracts, setContract] = useState([]);
    const [price_lists, setPrice_list] = useState([]);
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function get_contract_fetch() {
            let response = await get_contracts(driver_id)
            setContract(response)
        }
        get_contract_fetch();
    }, [driver_id]);
    useEffect(() => {
        async function get_cars_fetch() {
            let response = await get_cars({
                select: true,
                is_active: true
            })
            setCars(response)
        }
        get_cars_fetch();
    }, []);
    useEffect(() => {
        async function get_price_list_fetch() {
            let response = await get_price_list()
            setPrice_list(response)
        }
        get_price_list_fetch();
    }, []);

    const add_contract = async (data) => {
        if (loading === false) {
            let response = await api.post(`/contract/${driver_id}/`, data)
            setLoading(true)
            if (response.data.success === true) {
                setContract(response.data.data)
                setLoading(false)
            } else (
                setLoading(false)
            )
        }
    }
    return (
        <ShartnomaComponent
            contracts={contracts}
            add_contract={add_contract}
            price_lists={price_lists}
            cars={cars}
        />
    )
}

export default Shartnoma;