import React, { useEffect, useState } from 'react';
import { Container, Title, TitleContainer } from '../DriverList/styles';
import { BackBtn, Button, Form, FormItem, Input, Label } from './styles';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from "react-router-dom";

const DriverEditComponent = ({ add_driver, driver, nav_page }) => {
    let navigate = useNavigate();
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [passport, setPassport] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [jshshir, setJshshir] = useState("");
    const [stir, setStir] = useState("");
    const [deegre, setDeegre] = useState("");
    const [millati, setMillati] = useState("");
    const [kim_tomonidan, setKim_tomonidan] = useState("");
    const [passport_date, setPassport_date] = useState("");
    const [credit_card, setCredit_card] = useState("");
    const [start_date, setStart_date] = useState("");

    const setdriver = () => {
        setName(driver.name);
        setAddress(driver.address);
        setPhone(driver.phone);
        setPassport(driver.passport);
        setBirthdate(driver.birthdate);
        setJshshir(driver.jshshir);
        setStir(driver.stir);
        setDeegre(driver.deegre);
        setMillati(driver.millati);
        setKim_tomonidan(driver.kim_tomonidan);
        setPassport_date(driver.passport_date);
        setCredit_card(driver.credit_card);
        setStart_date(driver.start_date);
    }
    useEffect(() => {
        setdriver();
    }, [driver])

    const add = (e) => {
        const data = {
            name: name, address: address, phone: phone, passport: passport, birthdate: birthdate, jshshir: jshshir,
            stir: stir, deegre: deegre, millati: millati, kim_tomonidan: kim_tomonidan, passport_date: passport_date,
            credit_card: credit_card, start_date: start_date
        }
        add_driver(data)
        e.preventDefault();
    }

    const return_back = () => {
        navigate('/drivers', { state: { page: nav_page } })
    }
    return (
        <Container>
            <BackBtn onClick={return_back}><FaLongArrowAltLeft /></BackBtn>
            <TitleContainer>
                <Title style={{ fontSize: "20px" }}>Haydovchi tahrirlash</Title>
            </TitleContainer>
            <Form onSubmit={add}>
                <FormItem>
                    <Label>F.I.O</Label>
                    <Input type="text" placeholder='F.I.O' onChange={(e) => setName(e.target.value)} value={name} required />
                </FormItem>
                <FormItem>
                    <Label>Manzil</Label>
                    <Input type="text" placeholder='Manzil' onChange={(e) => setAddress(e.target.value)} value={address} required />
                </FormItem>
                <FormItem>
                    <Label>Tug'ilgan yil</Label>
                    <Input type="date" placeholder='Tugilgan yil' onChange={(e) => setBirthdate(e.target.value)} value={birthdate} required />
                </FormItem>
                <FormItem>
                    <Label>Telefon raqami</Label>
                    <Input type="text" placeholder='Telefon raqami' onChange={(e) => setPhone(e.target.value)} value={phone} required />
                </FormItem>
                <FormItem>
                    <Label>Millati</Label>
                    <Input type="text" placeholder='Millati' onChange={(e) => setMillati(e.target.value)} value={millati} required />
                </FormItem>
                <FormItem>
                    <Label>Pasport seriya raqami</Label>
                    <Input type="text" placeholder='Pasport seriya raqami' onChange={(e) => setPassport(e.target.value)} value={passport} required />
                </FormItem>
                <FormItem>
                    <Label>Berilgan vaqti</Label>
                    <Input type="date" placeholder='Berilgan vaqti' onChange={(e) => setPassport_date(e.target.value)} value={passport_date} required />
                </FormItem>
                <FormItem>
                    <Label>Kim tomonidan berilgan</Label>
                    <Input type="text" placeholder='Kim tomonidan berilgan' onChange={(e) => setKim_tomonidan(e.target.value)} value={kim_tomonidan} required />
                </FormItem>
                <FormItem>
                    <Label>JSHSHIR</Label>
                    <Input type="text" placeholder='JSHSHIR' onChange={(e) => setJshshir(e.target.value)} value={jshshir} required />
                </FormItem>
                <FormItem>
                    <Label>STIR</Label>
                    <Input type="number" placeholder='STIR' onChange={(e) => setStir(e.target.value)} value={stir} required />
                </FormItem>
                <FormItem>
                    <Label>Malumoti</Label>
                    <Input type="text" placeholder='Malumoti' onChange={(e) => setDeegre(e.target.value)} value={deegre} required />
                </FormItem>
                <FormItem>
                    <Label>Ishga kirgan sanasi</Label>
                    <Input type="date" placeholder='Ishga kirgan sanasi' onChange={(e) => setStart_date(e.target.value)} value={start_date} required />
                </FormItem>
                <FormItem>
                    <Label>Plastik karta</Label>
                    <Input type="text" placeholder='Plastik karta' onChange={(e) => setCredit_card(e.target.value)} value={credit_card} />
                </FormItem>
                <FormItem>
                    <Label></Label>
                    <Button>Tahrirlash</Button>
                </FormItem>

            </Form>
        </Container>
    );
}

export default DriverEditComponent;