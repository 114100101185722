import React, { useEffect, useState } from 'react'
import { FaDollarSign, FaFileAlt, FaList, FaRegBell, FaUsers, FaMailBulk } from 'react-icons/fa'
import { MdDashboard, MdDirectionsCar, MdOutlineSupervisorAccount, MdSettings, MdFilePresent } from 'react-icons/md'
import { Container, LogoContainer, LogoTitle, MenuBtn, MenuDiv, MenuIcon } from './styles'
import * as ROUTES from "../Constants/routes";
import { get_permission } from '../Constants/get_permission';


const Sidebar = () => {
    const [page, setPage] = useState("Dashboard");
    const [permission, setPermission] = useState([])
    useEffect(()=>{
        const path = window.location.pathname;
        setPage(path)
    })
    useEffect(() => {

        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])
    return (
        <Container>
            <LogoContainer>
                <LogoTitle>Web Taxi</LogoTitle>
            </LogoContainer>
            <MenuDiv>
                <MenuBtn to={ROUTES.DASHBOARD} onClick={()=>setPage(ROUTES.DASHBOARD)}>
                    <MenuIcon active={page === ROUTES.DASHBOARD ? true : false} >
                        <MdDashboard size={22} />
                    </MenuIcon>
                    Dashboard
                </MenuBtn>
                <MenuBtn to={ROUTES.DRIVERS}  onClick={()=>setPage(ROUTES.DRIVERS)}>
                    <MenuIcon active={page === ROUTES.DRIVERS ? true : false} >
                        <MdOutlineSupervisorAccount size={24} />
                    </MenuIcon>
                    Haydovchilar
                </MenuBtn>
                <MenuBtn to={ROUTES.CARS}  onClick={()=>setPage(ROUTES.CARS)}>
                    <MenuIcon active={page === ROUTES.CARS ? true : false} >
                        <MdDirectionsCar size={22} />
                    </MenuIcon>
                    Mashinalar
                </MenuBtn>
                <MenuBtn to={ROUTES.BALANCE}  onClick={()=>setPage(ROUTES.BALANCE)}>
                    <MenuIcon active={page === ROUTES.BALANCE ? true : false} >
                        <FaDollarSign size={22} />
                    </MenuIcon>
                    Kassa
                </MenuBtn>
                <MenuBtn to={ROUTES.STAFFS} onClick={()=>setPage(ROUTES.STAFFS)}>
                    <MenuIcon active={page === ROUTES.STAFFS ? true : false} >
                        <FaUsers size={22} />
                    </MenuIcon>
                    Xodimlar
                </MenuBtn>
                <MenuBtn to="/litsenziya" onClick={()=>setPage("/litsenziya" )}>
                    <MenuIcon active={page === "/litsenziya" ? true : false} >
                        <MdFilePresent size={22} />
                    </MenuIcon>
                    Litsenziya
                </MenuBtn>
                <MenuBtn to={ROUTES.NOTIFICATION} onClick={()=>setPage(ROUTES.NOTIFICATION)}>
                    <MenuIcon active={page === ROUTES.NOTIFICATION ? true : false} >
                        <FaRegBell size={22} />
                    </MenuIcon>
                    Bildirishnoma
                </MenuBtn>
                <MenuBtn to={ROUTES.VEDMOST} onClick={()=>setPage(ROUTES.VEDMOST)}>
                    <MenuIcon active={page === ROUTES.VEDMOST ? true : false} >
                        <FaFileAlt size={22} />
                    </MenuIcon>
                    Vedmost
                </MenuBtn>
                <MenuBtn to={'/messages'} onClick={()=>setPage('/messages')}>
                    <MenuIcon active={page === "/messages" ? true : false} >
                        <FaMailBulk size={22} />
                    </MenuIcon>
                    SMS
                </MenuBtn>
                <MenuBtn to={ROUTES.PRICE_LIST} onClick={()=>setPage(ROUTES.PRICE_LIST)}>
                    <MenuIcon active={page === ROUTES.PRICE_LIST ? true : false} >
                        <FaList size={22} />
                    </MenuIcon>
                    Tariflar
                </MenuBtn>
                <MenuBtn to={'/tex-view'} onClick={()=>setPage("/tex-view")}>
                    <MenuIcon active={page === "/tex-view" ? true : false} >
                        <FaList size={22} />
                    </MenuIcon>
                    Texnik ko'rik
                </MenuBtn>
                {permission.includes(20) ?
                    <MenuBtn to={ROUTES.SETTINGS} onClick={()=>setPage(ROUTES.SETTINGS)}>
                        <MenuIcon active={page === ROUTES.SETTINGS ? true : false} >
                            <MdSettings size={22} />
                        </MenuIcon>
                        Sozlamalar
                    </MenuBtn> : null}
            </MenuDiv>
        </Container>
    )
}

export default Sidebar