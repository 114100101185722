import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../Table/styles';
import { DateInput } from './styles';
import { FaEdit, FaTrash } from 'react-icons/fa';
import api from '../../api/api';
import { Container, Search, Title, TitleContainer } from '../Cars/CarList/styles';
import { DetailBtn } from '../Drivers/DriverList/styles';
import PaginationComponent from '../Paginations';



const get_davomat = async (params = null) => {
    const response = await api.get(`/davomat/`, { params: params })
    console.log(response);
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {
            result: [],
            pagination: {}
        }
    }
}

const TexComponent = () => {
    const [search, setSearch] = useState("");
    const [date_start, setDate_start] = useState(null);
    const [date_end, setDate_end] = useState(null);
    const [davomats, setDavomats] = useState([])
    const [pagination, setPagination] = useState({})
    useEffect(() => {
        async function get_davomat_fetch() {
            const response = await get_davomat()
            setDavomats(response.result)
            setPagination(response.pagination)

        }
        get_davomat_fetch()
    }, [])

    const SearchFilter = async (value) => {
        setSearch(value)
        if (date_start !== null && date_end !== null) {
            const response = await get_davomat({
                search: value,
                date_end: date_end,
                date_start: date_start
            })
            setDavomats(response.result)
            setPagination(response.pagination)
        } else {
            const response = await get_davomat({
                search: value
            })
            setDavomats(response.resault)
            setPagination(response.pagination)
        }
    }
    const loadData = async (page) => {
        const response = await get_davomat({
            page: page
        })
        setDavomats(response.result)
        setPagination(response.pagination)
    }
    const DateEndFilter = async (value) => {
        setDate_end(value)
        if (date_start !== null) {
            const response = await get_davomat({
                search: search,
                date_end: value,
                date_start: date_start
            })
            setDavomats(response.result)
            setPagination(response.pagination)
        }
    }
    const DateStartFilter = async (value) => {
        setDate_start(value)
        if (date_end !== null) {
            const response = await get_davomat({
                search: search,
                date_end: date_end,
                date_start: value
            })
            setDavomats(response.result)
            setPagination(response.pagination)
        }
    }

    return (
        <Container>
            <TitleContainer>
                <Title>Texnik ko'riklar</Title>
                <Search placeholder='Qidirish ... ' onChange={(e) => SearchFilter(e.target.value)} />
                <DateInput type="date" onChange={(e) => DateStartFilter(e.target.value)} />
                <DateInput type="date" onChange={(e) => DateEndFilter(e.target.value)} />
            </TitleContainer>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>№</TH>
                            <TH>Haydovchi</TH>
                            <TH>Telefon raqam</TH>
                            <TH>Ko'riklar soni</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {davomats?.map((dat, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dat.driver__name}</TD>
                                <TD>{dat.driver__phone}</TD>
                                <TD>{dat.count}</TD>
                            </TR>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationComponent
                activePage={pagination?.current_page}
                items_count={pagination?.items_count}
                range_display={5}
                loadData={loadData}
                items_per_page={pagination?.number_of_object}
            />
        </Container>
    );
}

export default TexComponent;