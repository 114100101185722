import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import api from '../api/api';
import Details from '../components/Drivers/Detail';

const get_driver = async (id) => {
    const response = await api.get(`/driver/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return null
    }
}

const get_car = async (id) => {
    const response = await api.get(`/driver-car/?driver=${id}`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return null
    }
}

const DriverDetail = () => {
    let location = useLocation()
    const { driver_id } = useParams()
    const [driver, setDriver] = useState([]);
    const [car, setCar] = useState({});
    useEffect(() => {
        async function get_car_fetch() {
            let response = await get_car(driver.id)
            setCar(response)
        }
        get_car_fetch();
    }, []);

    useEffect(() => {
        async function get_driver_fetch() {
            let response = await get_driver(driver_id)
            setDriver(response)
        }
        get_driver_fetch();
    }, [driver_id]);
    useEffect(() => {
        async function get_car_fetch() {
            let response = await get_car(driver_id)
            setCar(response)
        }
        get_car_fetch();
    }, [driver_id]);
    return (
        <Details driver={driver} car={car} nav_page={location?.state?.page} />
    );
}

export default DriverDetail;