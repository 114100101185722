import React, { useEffect, useState } from 'react'
import MessagesComponent from '../components/Messages';

import api from "../api/api"

const get_messages = async (params = {}) => {
    const response = await api.get('/messages/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    } else {
        console.log(response.data.error)
        return {}
    }
}

const Messages = () => {
    const [messages, setMessages] = useState({})
    useEffect(() => {
        async function get_message_fetch() {
            const res = await get_messages()
            setMessages(res)
        }
        get_message_fetch()
    }, [])

    const loadData = page => {
        async function get_message_fetch(params) {
            const res = await get_messages(params)
            setMessages(res)
        }
        get_message_fetch({ page: page })
    }
    return (
        <MessagesComponent messages={messages} loadData={loadData} />
    );
}

export default Messages;