import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../api/api';
import CarsComponent from '../components/Cars/CarList/Index';

const get_cars = async (params = {}) => {
    const response = await api.get('/car/', { params: params })
    console.log(response);
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {
            pagination: {},
            result: []
        }
    }
}

const Cars = () => {
    const { state } = useLocation()
    const [pagination, setPagination] = useState({});
    const [cars, setCars] = useState([]);

    useEffect(() => {
        async function get_cars_fetch() {
            let params = { is_active: true }
            if (state?.page === undefined) {
                params = {
                    is_active: true,
                }
            } else {
                params = {
                    is_active: true,
                    page: state.page
                }
            }
            let response = await get_cars(params)
            setPagination(response.pagination)
            setCars(response.result)
        }
        get_cars_fetch();
    }, []);

    const search_car = async (params) => {
        let response = await get_cars(params);
        setPagination(response.pagination)
        setCars(response.result)
    }



    return (
        <CarsComponent
            pagination={pagination}
            setPagination={setPagination}
            cars={cars}
            setCars={setCars}
            search_car={search_car}
        />
    )
}


export default Cars;