import React, { useEffect, useState } from 'react'
import api from '../api/api';
import { Container, DriverContainer, Title, TitleContainer } from '../components/Drivers/DriverList/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../components/Table/styles';



const get_vedmost = async (params) => {
    const response = await api.get('/vedmost/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}


const Notifications = () => {
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        async function get_drivers_fetch() {
            let response = await get_vedmost({
                notif: true
            })
            console.log(response);
            setDrivers(response)
        }
        get_drivers_fetch();
    }, []);
    return (
        <Container>
            <TitleContainer>
                <Title>Bildirishnomalar</Title>
            </TitleContainer>
            <DriverContainer>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TR>
                                <TH>№</TH>
                                <TH>FIO</TH>
                                <TH>Mashina</TH>
                                <TH>Telefon</TH>
                                <TH>Muddati</TH>
                            </TR>
                        </TableHead>
                        <TableBody>
                            {drivers?.map((dat, index) => (
                                <TR>
                                    <TD>{index + 1}</TD>
                                    <TD>{dat.name}</TD>
                                    <TD>{dat.model} ({dat.number})</TD>
                                    <TD>{dat.phone}</TD>
                                    <TD>{dat.contract}</TD>
                                </TR>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </DriverContainer>
            {/* <Paginations count={6}/> */}
        </Container>
    )
}


export default Notifications;