import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    color: #22242C;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const Card = styled.div`
    background-color: white;
    box-shadow: 1px 1px 1px 1px #8E8EA1;
    border: none;
    border-radius: 10px;
    padding: 15px;
    width: 85%;
    height: 80px;
    display: flex;
    align-items: center;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const CardSumma = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
`;

export const Subtitle = styled.div`
    color: #8E8EA1;
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
`;

export const Text = styled.div`
    font-size: 18px;
    font-family: "Inter";
    font-style: normal;
    color: black;
    font-weight: 600;
`;

export const CardIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: ${props=>props.bg? props.bg:"#6160DC"};
    color: "#fffff";
    margin-left: auto;
`;

export const CardContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 25% 25% 25% 25%;
    @media (max-width: 1666px){
        grid-template-columns: 50% 50%;
    }
`;