import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import { FormItem, Input, Label } from '../../Drivers/DriverAdd/styles';
import { Btn, BtnDiv, Container, Form, Text } from './styles';
import "./styles.css";

Modal.setAppElement("#root")


const PaswordEditModal = ({ isOpen, setIsOpen, EditParol }) => {
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [parol, setParol] = useState("");
  const Submit_h = (e) => {
    e.preventDefault()
    EditParol({ password: parol, method: "Parol" })
  }
  useEffect(() => {
    setParol("");
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      <Container>
        <Form onSubmit={(e) => Submit_h(e)}>
          <FormItem style={{ paddingBottom: "10px" }}>
            <Label>Parol</Label>
            <Input type="text" placeholder='Parol' value={parol} onChange={(e) => setParol(e.target.value)} />
          </FormItem>
          <BtnDiv>
            <Btn>Tahrirlash</Btn>
            <Btn bg="#e61c1c" onClick={toggleModal} type="button">Yopish</Btn>
          </BtnDiv>
        </Form>

      </Container>
    </Modal>
  );
}
export default PaswordEditModal;