import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 15px;
`;

export const Title = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #22242C;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const DriverContainer = styled.div`
    display: flex;
    overflow-x:auto;
`;

export const Search = styled.input`
    background-color: white;
    font-size: 15px;
    border: 1px solid #999;
    height: 32px;
    min-width: 100px;
    max-width: 200px;
    box-sizing: border-box;
    font-family: 'Inter';
    border-radius: 10px;
    outline: none;
    margin-left: auto;
    margin-right: 15px;
    padding-left: 5px;
`;


export const AddButton = styled(Link)`
  font-size: 16px;
  width: 130px;
  height: 32px;
  font-family: "Inter";
  color: white;
  border: none;
  border-radius: 10px;
  background-color: #6160DC;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 3px;
  cursor: pointer;
  margin-left: auto;
  :hover{
      background-color: #00c4cf;
  }
`;