import React, { useEffect, useState } from 'react'
import api from '../api/api';
import Payments from '../components/Balance/Payments';
import Widgets from '../components/Balance/Widgets';
import { Title } from '../components/Drivers/DriverList/styles';
import { get_permission } from '../Constants/get_permission';
const get_payments = async (params={}) =>{
    const response = await api.get('/payment/',{params:params})
    if (response.data.success === true){
        return response.data.data
    }
    else{
        return {
            pagination:{},
            result:[]
        }
    }
}

const get_widget = async () =>{
    const response = await api.get('/kassa-widget/')
    if (response.data.success === true){
        return response.data.data
    }
    else{
        return {}
    }
}
const Balance = () =>{
    const [payments, setPayments] = useState({})
    const [widget, setWidget] = useState({})
    const [permission, setPermission] = useState([])
    useEffect(()=>{
        async function get_payments_fetch(){
            let response = await get_payments()
            setPayments(response)
         }
         get_payments_fetch()
    },[]);

    useEffect(()=>{
        async function get_widget_fetch(){
            let response = await get_widget()
            setWidget(response)
         }
         get_widget_fetch()
    },[]);
    useEffect(() => {

        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])
    const filter_payments = async (params) =>{
        let response = await get_payments(params)
        setPayments(response)
    }
    return (
        <>
        <Title>Kassa</Title>
        <Widgets widget={widget} permission={permission}/>
        <Payments payments={payments} filter_payments={filter_payments} />
        </>
    )
}


export default Balance;