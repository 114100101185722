import React, { useState } from 'react'
import { Card, CardContent, Container, DeleteBtn, ExampleContainer, FilterBtn, LeftBottom, LeftMidle, LeftSection, LeftTop, Main, MessageContainer, MessageInput, Name, RightSection, SearchInput, SendBtn, Text, Title, Top, TopContent, TopFilter } from './styles';



const MessageDetailComponent = ({ message }) => {

    console.log(message);
    return (
        <Container>
            <Top>
                <Title>SMS</Title>
            </Top>
            <Main>
                <LeftSection>
                    <LeftMidle className={"max"}>
                        {message?.drivers?.map((driver, index) => (
                            <Card key={index}>
                                <TopContent>
                                    <Name>{driver.name}</Name>
                                </TopContent>
                                <CardContent>
                                    <Text>Mashina : {driver.car}</Text>
                                    <Text>Qarzdorlik : {driver.credit}</Text>
                                    <Text>Tug'ligan sana : {driver.birthdate}</Text>
                                    <Text>Litsenziya : {driver.let}</Text>
                                </CardContent>
                            </Card>

                        ))}

                    </LeftMidle>

                </LeftSection>
                <RightSection>
                    <Name style={{ width: "100%", margin: "20px", marginTop: "35px", color: "#fc8803" }}>Haydovchilarga tegishli ma'lumotlarni xabar ichiga joylash uchun yoriqnoma.</Name>

                    <ExampleContainer>
                        <Text>[haydovchi] = Haydovchining ismi familyasi</Text>
                        <Text>[qarz] = Haydovchining qarzi</Text>
                        <Text>[Litsenziya_muddati] = Litsenziya muddati</Text>
                        <Text>[mashina_qarz] = Daromad solig'idan qarzi</Text>
                    </ExampleContainer>
                    <MessageContainer>
                        <MessageInput disabled value={message.text}>{message}</MessageInput>
                    </MessageContainer>
                </RightSection>
            </Main>
        </Container>
    )
}


export default MessageDetailComponent;