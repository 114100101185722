import React, { useEffect, useRef, useState } from 'react'
import { Container } from '../CarTab/styles';
import { Button, CheckDiv, CheckItem, Form, Input, QRDiv, Text, Title } from './styles';
import api from '../../../../api/api';
import { Select } from '../../../Cars/CarAdd/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../../Table/styles';
import Swal from 'sweetalert2';
import { useReactToPrint } from 'react-to-print';
import { PrintButton } from '../../../../pages/vedmost';
import { FaPrint } from 'react-icons/fa';
import { get_permission } from '../../../../Constants/get_permission';
import PaginationComponent from '../../../Paginations';
import QRCode from 'react-qr-code';
const get_payment_types = async () => {
    const response = await api.get('/payment-type/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}
const get_payments = async (params) => {
    const response = await api.get('/payment/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {
            pagination: {},
            result: []
        }
    }
}

const Print = React.forwardRef((props, ref) => {
    const date = new Date()
    return (
        <CheckDiv ref={ref}>
            <Title style={{ backgroundColor: "#999999", padding: "5px" }}>Web-taxi</Title>
            <Title>Квитанция: {props?.printPayment?.id}</Title>
            <CheckItem>
                <Text>Чоп этилган санаси:</Text>
                <Text>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Авто Русуми:</Text>
                <Text>{props.printPayment?.driver?.car_model}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Авто Раками:</Text>
                <Text>{props.printPayment?.driver?.car_number}</Text>
            </CheckItem>
            <CheckItem>
                <Text>СТИР:</Text>
                <Text>{props.printPayment?.driver?.stir}</Text>
            </CheckItem>
            <CheckItem>
                <Text>ЖЩШИР:</Text>
                <Text>{props.printPayment?.driver?.jshshir}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тўлов суммаси:</Text>
                <Text>{props.printPayment.summa}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тулов Максади: </Text>
                <Text>{props.printPayment.type}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тўлов санаси:</Text>
                <Text>{props.printPayment.date}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Туловчи:</Text>
                <Text>{props.printPayment?.driver?.name}</Text>
            </CheckItem>
            <QRDiv>
                <Title style={{ fontSize: "15px", textAlign: "left", margin: "10px 0px", fontStyle: "italic" }}>Ёрдамингиз билан ривожланмоқдамиз!</Title>
                <QRCode value={String(props.printPayment.id)} size={64} style={{ marginLeft: "10px" }} />
            </QRDiv>

        </CheckDiv>
    )
});


const PaymentTab = ({ driver_id }) => {
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [payments, setPayments] = useState({})
    const [summa, setSumma] = useState("");
    const [type, setType] = useState(0);
    const [printPayment, setPrintPayment] = useState({});
    const componentRef = useRef();
    const [printOpen, setPrintOpen] = useState(false)
    const [permission, setPermission] = useState([])
    const [loading, setLoading] = useState(false);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const PrintPaymentHandle = (data) => {
        setPrintPayment(data)
        setPrintOpen(true)
    }

    const loadData = async (page) => {
        let response = await get_payments({
            driver: driver_id,
            page: page
        })
        setPayments(response)
    }

    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])

    useEffect(() => {
        if (printOpen === true) {
            handlePrint()
            setPrintOpen(false)
        }

    }, [printPayment])
    useEffect(() => {
        async function get_payment_types_fetch() {
            let response = await get_payment_types()
            setPaymentTypes(response)
        }
        get_payment_types_fetch()
    }, []);
    useEffect(() => {
        async function get_payments_fetch() {
            let response = await get_payments({
                driver: driver_id
            })
            setPayments(response)
        }
        get_payments_fetch()
    }, [driver_id]);

    const add = async (e) => {
        e.preventDefault();
        if (loading === false) {
            if (type === 0) {
                Swal.fire({
                    title: 'Error!',
                    text: "To'lov turi tanlanmadi!",
                    icon: 'error'
                });
            } else {
                let response = await api.post('/payment/', {
                    type: type,
                    driver: driver_id,
                    summa: summa
                })
                setLoading(true)
                if (response.data.success === true) {
                    setPayments(response.data.data)
                    Swal.fire({
                        title: 'Success!',
                        text: "To'lov qabul qilindi!",
                        icon: 'success'
                    });
                    setType(0);
                    setSumma("");
                    setLoading(false)
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: "To'lov qabul qilishda xatolik!",
                        icon: 'error'
                    });
                    setLoading(false)
                }
            }
        }


    }

    return (
        <Container>
            {permission?.includes(7) ?
                <Form onSubmit={add}>
                    <Input type="number" placeholder='Summa' onChange={(e) => setSumma(e.target.value)} value={summa} required />
                    <Select style={{ paddingRight: "10px" }} onChange={(e) => setType(e.target.value)}>
                        {type === 0 ?
                            <option selected disabled value={type}>To'lov turini tanlang</option> :
                            <option disabled value={type}>To'lov turini tanlang</option>
                        }
                        {paymentTypes?.map((py, index) => (
                            <option value={py.id}>{py.name}</option>
                        ))}
                    </Select>
                    <Button>To'lash</Button>
                </Form> : null}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>№</TH>
                            <TH>Summa</TH>
                            <TH>To'lov turi</TH>
                            <TH>Sana</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {payments?.result?.map((dat, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dat.summa}</TD>
                                <TD>{dat.type}</TD>
                                <TD>{dat.date}</TD>
                                <TD><PrintButton bg="#4287f5" onClick={() => PrintPaymentHandle(dat)} key={index}>
                                    <FaPrint size={18} />
                                </PrintButton></TD>
                            </TR>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationComponent
                activePage={payments?.pagination?.current_page}
                items_count={payments?.pagination?.items_count}
                range_display={5}
                loadData={loadData}
                items_per_page={payments?.pagination?.number_of_object}
            />
            <div style={{ display: "none" }}>
                <Print ref={componentRef} printPayment={printPayment} />

            </div>
        </Container>
    );
}

export default PaymentTab;