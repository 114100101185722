import { Link } from "react-router-dom";
import styled from "styled-components";


export const Card = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap:15px;
    border: 1px solid #9999;
    min-height: 60px;
    border-radius: 7px;
    transition: all 0.4s;
    padding: 10px;
    cursor: pointer;
    &:hover{
        transform: scale(0.99);
    }
    
`;

export const DateText = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #22242C;
`;

export const Text = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    color: #999999;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    margin-top: 15px;
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-right: 30px;
    align-items: center;
`;

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-right: 30px;
    grid-gap: 10px;
`;



export const Title = styled.p`
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #22242C;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const Button = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 46px;
    font-size: 20px;
    color: white;
    background-color: #eb8934;
    transition: all 0.3s;
    border-radius: 7px;
    &:hover{
        transform: scale(1.02);
    }
`;