import styled from "styled-components";


export const ContainerFull = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border: none;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
`;

export const MainContainer = styled.div`
    display: grid;
    grid-template-columns: 25% 20% 18% 18% 10%;
    padding: 20px;
    grid-gap: 20px;
    align-items: center;
    height: 100px;
    width: 100%;
`;

export const TabMenuContainer = styled.div`
    display: flex;
    grid-gap:10px;
    align-items: center;
    justify-content: flex-start;
    margin: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
`;

export const TabBtn = styled.button`
    height: 32px;
    font-size: 16px;
    border: none;
    background-color: white;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    color: #717375;
    border-bottom: ${props=>props.active === true?"2px solid #2e80f2":"none"};
    cursor: pointer;
    :hover{
        border-bottom:2px solid #2e80f2;
    }
`; 

export const TabContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 20px;
`;

export const Avatar = styled.div`
    background-color: #54C5EB;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const TitleItem = styled.span`
    font-size: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    color: ${props=>props.color?props.color:"#54C5EB"};
    margin-bottom: 10px;
`;

export const SubTitle = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 18px;
    color: ${props=>props.color?props.color:"#8E8EA1"};
    margin-bottom: 15px;
`;

export const ItemDiv = styled.div`
    display: flex;
    flex-direction: column;
`;



export const DriverDiv = styled.div`
    display: flex;
    grid-gap: 15px;
    align-items: center;
`;

export const LitsenziyaDiv = styled.div``;

export const CarDiv = styled.div``;