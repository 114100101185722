import React, { useEffect, useState } from 'react';
import { BackBtn } from '../../Drivers/DriverEdit/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../Table/styles';
import { Container, Title, TitleContainer } from '../CarList/styles';
import { Button, Form, FormItem, Input, Select, Label } from './styles';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const CarEditComponent = ({ add_car, models, car, add_let, staffs, nav_page }) => {
    let navigate = useNavigate();
    const [owner_name, setOwnerName] = useState(car.owner_name);
    const [address, setAddress] = useState(car.address);
    const [phone, setPhone] = useState(car.phone);
    const [passport, setPassport] = useState(car.passport);
    const [birthdate, setBirthdate] = useState(car.birthdate);
    const [jshshir, setJshshir] = useState(car.jshshir);
    const [stir, setStir] = useState(car.stir);
    const [model, setModel] = useState(car.model ? car.model : 0);
    const [number, setNumber] = useState(car.number);
    const [year, setYear] = useState(car.year);
    const [tex_passport, setTex_passport] = useState(car.tex_passport);
    const [tex_date, setTex_date] = useState(car.tex_date);
    const [raqam, setRaqam] = useState("");
    const [let_date_start, setLetDate_start] = useState("");
    const [let_date_end, setLetDate_end] = useState("");
    const [inspektor, setInspektor] = useState(0);

    const setcar = () => {
        setOwnerName(car.owner_name);
        setAddress(car.address);
        setPhone(car.phone);
        setPassport(car.passport);
        setBirthdate(car.birthdate);
        setJshshir(car.jshshir);
        setStir(car.stir);
        setModel(car.model);
        setNumber(car.number);
        setYear(car.year);
        setInspektor(car.inspektor ? car.inspektor : 0)
        setTex_passport(car.tex_passport);
        setTex_date(car.tex_date);
    }
    useEffect(() => {
        setcar()
    }, [car])

    const clear_let = () => {
        setRaqam("");
        setLetDate_start("");
        setLetDate_end("");
    }
    const add = (e) => {
        const data = {
            owner_name: owner_name, address: address, phone: phone, passport: passport, birthdate: birthdate, jshshir: jshshir,
            stir: stir, model: model, number: number, year: year,
            tex_passport: tex_passport, tex_date: tex_date, inspektor: inspektor
        }
        add_car(data, setcar)
        e.preventDefault();
    }

    const add_let_submit = (e) => {
        const let_data = {
            raqam: raqam, let_date_start: let_date_start, let_date_end: let_date_end
        }
        add_let(let_data, clear_let)
        e.preventDefault();
    }

    const return_back = () => {
        navigate('/cars', { state: { page: nav_page } })
    }
    return (
        <Container>
            <BackBtn onClick={return_back}><FaLongArrowAltLeft /></BackBtn>
            <TitleContainer>
                <Title>Mashina tahrirlash</Title>
            </TitleContainer>
            <Form onSubmit={(e) => add(e)}>
                <FormItem>
                    <Label>Mashina Rusumi</Label>
                    <Select onChange={(e) => setModel(e.target.value)}>
                        {model === 0 ?
                            <option selected disabled>Mashina rusumini tanlang</option> :
                            <option disabled>Mashina rusumini tanlang</option>
                        }

                        {models?.map((md, index) => (
                            <option value={md.id} selected={md.id === model ? "selected" : null}>{md.name}</option>
                        ))}

                    </Select>
                </FormItem>
                <FormItem>
                    <Label>Mashina davalat raqami</Label>
                    <Input type="text" placeholder='Mashina  davalat raqami' onChange={(e) => setNumber(e.target.value)} value={number} required />
                </FormItem>
                <FormItem>
                    <Label>Ishlab CH.Y</Label>
                    <Input type="number" placeholder='Ishlab CH.Y' onChange={(e) => setYear(e.target.value)} value={year} required />
                </FormItem>
                <FormItem>
                    <Label>Texpasporti</Label>
                    <Input type="text" placeholder='Texpasporti seria raqami' onChange={(e) => setTex_passport(e.target.value)} value={tex_passport} required />
                </FormItem>
                <FormItem>
                    <Label>Texpasprot berilgan sana</Label>
                    <Input type="date" placeholder='Tex pasprot berilgan sana' onChange={(e) => setTex_date(e.target.value)} value={tex_date} required />
                </FormItem>
                <FormItem>
                    <Label>Egasi F.I.O</Label>
                    <Input type="text" placeholder='F.I.O' onChange={(e) => setOwnerName(e.target.value)} value={owner_name} required />
                </FormItem>
                <FormItem>
                    <Label>Manzil</Label>
                    <Input type="text" placeholder='Manzil' onChange={(e) => setAddress(e.target.value)} value={address} required />
                </FormItem>
                <FormItem>
                    <Label>Tug'ilgan yil</Label>
                    <Input type="date" placeholder='Tugilgan yil' onChange={(e) => setBirthdate(e.target.value)} value={birthdate} required />
                </FormItem>
                <FormItem>
                    <Label>Telefon raqami</Label>
                    <Input type="text" placeholder='Telefon raqami' onChange={(e) => setPhone(e.target.value)} value={phone} required />
                </FormItem>
                <FormItem>
                    <Label>Pasport seriya raqami</Label>
                    <Input type="text" placeholder='Pasport seriya raqami' onChange={(e) => setPassport(e.target.value)} value={passport} required />
                </FormItem>
                <FormItem>
                    <Label>JSHSHIR</Label>
                    <Input type="text" placeholder='JSHSHIR' onChange={(e) => setJshshir(e.target.value)} value={jshshir} required />
                </FormItem>
                <FormItem>
                    <Label>STIR</Label>
                    <Input type="number" placeholder='STIR' onChange={(e) => setStir(e.target.value)} value={stir} required />
                </FormItem>
                <FormItem>
                    <Label>Inspektor</Label>
                    <Select onChange={(e) => setInspektor(e.target.value)}>
                        {inspektor === 0 ?
                            <option selected disabled>Inspektorni tanlang</option> :
                            <option disabled>Inspektorni tanlang</option>
                        }

                        {staffs?.map((md, index) => (
                            <option value={md.id} selected={md.id === inspektor ? "selected" : null}>{md.name}</option>
                        ))}

                    </Select>
                </FormItem>
                <FormItem>
                    <Label></Label>
                    <Button>Tahrirlash</Button>
                </FormItem>

            </Form>
            <Title>Litsenziya</Title>
            <Form onSubmit={(e) => add_let_submit(e)}>
                <FormItem>
                    <Label>Litsenziya Raqami</Label>
                    <Input type="text" placeholder='Litsenziya Raqami' onChange={(e) => setRaqam(e.target.value)} value={raqam} required />
                </FormItem>
                <FormItem>
                    <Label>Litsenziya boshlanish sana</Label>
                    <Input type="date" onChange={(e) => setLetDate_start(e.target.value)} value={let_date_start} required />
                </FormItem>
                <FormItem>
                    <Label>Litsenziya tugash sana</Label>
                    <Input type="date" onChange={(e) => setLetDate_end(e.target.value)} value={let_date_end} required />
                </FormItem>
                <FormItem>
                    <Label></Label>
                    <Button>Qo'shish</Button>
                </FormItem>
            </Form>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>№</TH>
                            <TH>Litsenziya Raqami</TH>
                            <TH>Boshlanish sana</TH>
                            <TH>Tugash sana</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {car.let?.map((dat, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dat.raqam}</TD>
                                <TD>{dat.date_start}</TD>
                                <TD>{dat.date_end}</TD>
                            </TR>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default CarEditComponent;