import React, { useEffect, useRef, useState } from 'react'
import { Container } from '../CarTab/styles';
import { Button, CheckDiv, CheckItem, Form, Input, Text, Title } from './styles';
import api from '../../../../api/api';
import { Select } from '../../../Cars/CarAdd/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../../Table/styles';
import Swal from 'sweetalert2';
import { useReactToPrint } from 'react-to-print';
import { PrintButton } from '../../../../pages/vedmost';
import { FaPrint } from 'react-icons/fa';
import { FormItem, Label } from '../../DriverAdd/styles';
import PaginationComponent from '../../../Paginations';
import { QRDiv } from '../PaymentTab/styles';
import QRCode from 'react-qr-code';

const get_tex_korik = async (pk, params = null) => {
    const response = await api.get(`/tex-korik/${pk}/`, { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const Print = React.forwardRef((props, ref) => {
    const date = new Date()
    return (
        <CheckDiv ref={ref}>
            <Title style={{ backgroundColor: "#999999", padding: "5px" }}>Web-taxi</Title>
            <Title>Рухсатнома: {props?.printPayment?.id}</Title>
            <CheckItem>
                <Text style={{ fontWeight: "600" }}>Чоп этилган санаси:</Text>
                <Text>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</Text>
            </CheckItem>
            <CheckItem>
                <Text style={{ fontWeight: "600" }}>Авто русуми:</Text>
                <Text>{props.printPayment?.car?.model}</Text>
            </CheckItem>
            <CheckItem>
                <Text style={{ fontWeight: "600" }}>Авто рақами:</Text>
                <Text>{props.printPayment?.car?.number}</Text>
            </CheckItem>
            <CheckItem>
                <Text style={{ fontWeight: "600" }}>Хайдовчи:</Text>
                <Text>{props.driver?.name}</Text>
            </CheckItem>
            <CheckItem>
                <Text style={{ fontWeight: "600" }}>Тиббий кўрик рухсат:</Text>
            </CheckItem>
            <CheckItem>
                <Text></Text>
                <Text>{props.printPayment?.date_start}</Text>
            </CheckItem>
            <CheckItem>
                <Text></Text>
                <Text>{props.printPayment.date_end}</Text>
            </CheckItem>
            <CheckItem>
                <Text style={{ fontWeight: "600" }}>Техник кўрик рухсат:</Text>
            </CheckItem>
            <CheckItem>
                <Text></Text>
                <Text>{props.printPayment?.date_start}</Text>
            </CheckItem>
            <CheckItem>
                <Text></Text>
                <Text>{props.printPayment.date_end}</Text>
            </CheckItem>
            <CheckItem style={{ margin: "10px 0px" }}>
                <Text style={{ fontWeight: "600", fontStyle: "italic" }}>Эслатма! Юқорида кўрсатилган муддатдан кегин хеч қандай
                    кўриклар ўтказилмайди
                    рухсатномани ўз вақтида
                    янгилашни унутманг.
                </Text>
            </CheckItem>
            <QRDiv>
                <Title style={{ fontSize: "15px", textAlign: "left", margin: "10px 0px", fontStyle: "italic" }}>Ёрдамингиз билан ривожланмоқдамиз!</Title>
                <QRCode value={String(props?.printPayment?.id)} size={64} style={{ marginLeft: "10px" }} />
            </QRDiv>

        </CheckDiv>
    )
});


const TexKorikTab = ({ car, driver }) => {
    const [koriklar, setKoriklar] = useState([])
    const [date_start, setDate_start] = useState("");
    const [date_end, setDate_end] = useState("");
    const [printPayment, setPrintPayment] = useState({});
    const componentRef = useRef();
    const [printOpen, setPrintOpen] = useState(false)
    const [pagination, setPagination] = useState({})
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const PrintPaymentHandle = (data) => {
        setPrintPayment(data)
        setPrintOpen(true)
    }
    const loadData = async (page) => {
        let response = await get_tex_korik(car.id, { page: page })
        setKoriklar(response.result)
        setPagination(response.pagination)
    }
    useEffect(() => {
        if (printOpen === true) {
            handlePrint()
            setPrintOpen(false)
        }

    }, [printPayment])
    useEffect(() => {
        async function get_tex_korik_fetch() {
            let response = await get_tex_korik(car.id)
            setKoriklar(response.result)
            setPagination(response.pagination)
        }
        get_tex_korik_fetch()
    }, [car]);

    const add = async (e) => {
        e.preventDefault();
        let response = await api.post(`/tex-korik/${car.id}/`, {
            date_start: date_start,
            date_end: date_end
        })
        if (response.data.success === true) {
            setKoriklar(response.data.data.result)
            setPagination(response.data.data.pagination)
            Swal.fire({
                title: 'Success!',
                text: "Texnik ko'rik qo'shildi!",
                icon: 'success'
            });
            setDate_start("");
            setDate_end("")
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Texnik ko'rik qo'shishda xatolik!",
                icon: 'error'
            });
        }
        e.preventDefault();

    }

    return (
        <Container>
            <Form onSubmit={(e) => add(e)}>
                <FormItem>
                    <Label>Mashina</Label>
                    <Input type="text" value={car?.number} disabled />
                </FormItem>
                <FormItem>
                    <Label>Sana dan</Label>
                    <Input type="date" onChange={(e) => setDate_start(e.target.value)} value={date_start} required />
                </FormItem>
                <FormItem>
                    <Label>Sana gacha</Label>
                    <Input type="date" onChange={(e) => setDate_end(e.target.value)} value={date_end} required />
                </FormItem>
                <FormItem>
                    <Label></Label>
                    <Button>Qo'shish</Button>
                </FormItem>

            </Form>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>№</TH>
                            <TH>Mashina</TH>
                            <TH>Dan</TH>
                            <TH>Gacha</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {koriklar?.map((dat, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dat.car.model}({dat.car.number})</TD>
                                <TD>{dat.date_start}</TD>
                                <TD>{dat.date_end}</TD>
                                <TD><PrintButton bg="#4287f5" onClick={() => PrintPaymentHandle(dat)} key={index}>
                                    <FaPrint size={18} />
                                </PrintButton></TD>
                            </TR>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationComponent
                activePage={pagination.current_page}
                items_count={pagination.items_count}
                range_display={5}
                loadData={loadData}
                items_per_page={pagination.number_of_object}
            />
            <div style={{ display: "none" }}>
                <Print ref={componentRef} printPayment={printPayment} driver={driver} />

            </div>
        </Container>
    );
}

export default TexKorikTab;