import React from 'react';
import { Container, Table, TableBody, TableHead, TD, TH, Title, TitleContainer, TR } from './styles';

const VedmostComponent = React.forwardRef((props, ref) => {
    
        var date = new Date();
        return ( 
            <Container ref={ref}>
                <TitleContainer>
                <Title>"Dream Gold Andijan" МЧЖ {date.getDate()}.{date.getMonth()+1< 10 ?"0"+String(date.getMonth()+1):date.getMonth()+1 }.{date.getFullYear()}-йил кунли хайдовчилар руйхати</Title>
                </TitleContainer>
                
                <Table>
                    <TableHead>
                        <TR>
                            <TH style={{width:"3%"}}>№</TH>
                            <TH>Хайдвочи Ф.И.Ш</TH>
                            <TH style={{width:"8%"}}>Авто русуми</TH>
                            <TH>Давлат раками</TH>
                            <TH>Сумма</TH>
                            <TH>Имзо</TH>
                            <TH>Тел раками</TH>
                            <TH style={{width:"10%"}}>Лицензия муддати</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {props.drivers?.map((dr, index)=>(
                            <TR>
                                <TD>{index+1}</TD>
                                <TD>{dr.name}</TD>
                                <TD>{dr.model}</TD>
                                <TD>{dr.number}</TD>
                                <TD></TD>
                                <TD></TD>
                                <TD>{dr.phone}</TD>
                                <TD>{dr.contract}</TD>
                            </TR>
                        ))}
                    </TableBody>
                </Table>
            </Container>
         );
    
})

export default VedmostComponent;
 