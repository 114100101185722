import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

`;

export const SignInDiv = styled.div`
    display: flex;
    width: 350px;
    flex-direction: column;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 3px 3px 3px 3px #999;;
`;

export const LogoTitle = styled.div`
    font-size: 28px;
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
    margin-bottom: 20px;
    color: #fcdb03;
    text-align: center;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;

`;

export const Label = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #5E5E5E;
    margin-bottom: 8px;
`;

export const Input = styled.input`
    border: 1px solid #767676;
    box-sizing: border-box;
    font-size: 15px;
    height: 36px;
    outline: none;
    :focus{
        border: 1px solid #03cafc;
    }
    border-radius: 8px;
    margin-bottom: 20px;
`;

export const Button = styled.button`
    background-color: #6160DC;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    height: 36px;
    width: 200px;
    color: white;
    outline: none;
    border: none;
    border-radius: 8px;
    :hover{
        opacity: 50%;
    }
    margin-top:10px;
    margin-bottom: 10px;
    
`;