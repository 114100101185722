import styled from "styled-components";
import { Link } from "react-router-dom";

export const MenuBtn = styled(Link)`
    border: none;
    display: flex;
    text-decoration: none;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    color: #8E8EA1;
    background-color: white;
    cursor: pointer;
    :hover{
        background-color: #e0dfe6;
    }
    padding: 5px;
`;


export const MenuIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    background-color: ${props=>props.active === true? "#6160DC":null};
    color: ${props=>props.active === true? "#fff":"#8E8EA1"};;
    margin-right: 17px;
`;

export const MenuDiv = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 7px;
    margin-left: 20px;
`;


export const Container = styled.div`
    display: flex;
    min-width: 250px;
    background-color: white;
    height: 100vh;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
`;


export const LogoContainer = styled.div`
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const LogoTitle = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    color: #000000;
    margin: 15px;
`;