import React, { useEffect, useRef, useState } from 'react'
import { get_permission } from '../../../Constants/get_permission';
import { Input } from '../../Drivers/DriverAdd/styles';
import { CarTab } from '../../Drivers/Tabs';
import { Button, FormItem, Label } from '../CarAdd/styles';
import { Title } from '../CarList/styles';
import { Container, Form } from './styles';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BackBtn } from '../../Drivers/DriverEdit/styles';
import { useReactToPrint } from 'react-to-print';
import { ButtonContainer } from '../../Drivers/Tabs/Document/styles';
import { PrintButton } from '../../../pages/vedmost';
import { FaPrint } from "react-icons/fa";


const CarDetailComponent = ({ car, add_car_ijara, nav_page }) => {
    let navigate = useNavigate();
    const [date_start, setDate_start] = useState("");
    const [date_end, setDate_end] = useState("");
    const [price, setPrice] = useState("");
    const [raqam, setRaqam] = useState("");
    const [permission, setPermission] = useState([])
    const pathRef = useRef();
    const handlePathPrint = useReactToPrint({
        content: () => pathRef.current,
    });
    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])

    const add = (e) => {
        add_car_ijara({
            date_start: date_start,
            date_end: date_end,
            price: price,
            car_id: car.id,
            raqam: raqam
        })
        setDate_end("");
        setDate_start("");
        setPrice("");
        e.preventDefault();
    }

    if (car === null) {
        return (
            <Container>
                <Title>Mashina topilmadi!</Title>
            </Container>
        )
    }
    const return_back = () => {
        navigate('/cars', { state: { page: nav_page } })
    }

    return (
        <Container>
            <BackBtn onClick={return_back}><FaLongArrowAltLeft /></BackBtn>
            <Title>Mashina haqida</Title>
            {permission?.includes(6) ?
                <Form onSubmit={add}>
                    <FormItem>
                        <Label>Ijara raqami</Label>
                        <Input type="text" placeholder='Ijara raqami' onChange={(e) => setRaqam(e.target.value)}
                            value={raqam} required />
                    </FormItem>
                    <FormItem>
                        <Label>Ijara boshlanish sana</Label>
                        <Input type="date" onChange={(e) => setDate_start(e.target.value)}
                            value={date_start} required />
                    </FormItem>
                    <FormItem>
                        <Label>Ijara tugash sana</Label>
                        <Input type="date" onChange={(e) => setDate_end(e.target.value)}
                            value={date_end} required />
                    </FormItem>
                    <FormItem>
                        <Label>Ijara narxi</Label>
                        <Input type="number" placeholder='Ijara narxi' onChange={(e) => setPrice(e.target.value)}
                            value={price} required />
                    </FormItem>
                    <FormItem>
                        <Label></Label>
                        <Button style={{ marginBottom: "0px", width: "150px" }}>Qo'shish</Button>
                    </FormItem>

                </Form> : null}
            <ButtonContainer>
                <PrintButton bg="#0d9445" onClick={handlePathPrint}>
                    <FaPrint size={24} style={{ paddingRight: "10px" }} />
                </PrintButton>
            </ButtonContainer>
            <CarTab car={car} ref={pathRef} />
        </Container>
    );
}

export default CarDetailComponent;